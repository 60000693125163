import React, { useEffect } from 'react'
import {Col, Grid, Row, Table} from "rsuite";
import {mockUsers} from "./mock";
import {Button} from "@aws-amplify/ui-react";
import { useCustomQuery } from '../../../core/hooks/use-custom-query';
import { userMonitoringApi } from '../../../core/http/user-monitoring';
import { Link, useParams, useSearchParams } from 'react-router-dom';

const { Column, HeaderCell, Cell } = Table;

// const data = mockUsers(20);

const Sessions = () => {
  const [searchParams] = useSearchParams()
  const { orgId } = useParams<{ orgId: string }>()
  const [sessions, setSessions] = React.useState<any[]>([])

  const { data, isLoading } = useCustomQuery('sessions', () => userMonitoringApi.getProjectSessions(orgId || "", searchParams.get('project')!))

  useEffect(() => {
    if (data && data?.length > 0) {
      setSessions(data?.map((session: any) => ({
        ...session,
        userAgent: session?.data?.browserInfo?.user?.agent
      })))

      console.log(data?.map((session: any) => ({
        ...session,
        userAgent: session?.browserInfo?.user?.agent
      })))
    }
  }, [data])

  return (
    <Grid fluid style={{ height: "calc(100vh - 200px)" }}>

      <Row>
        <Col xs={4}>xs={2}</Col>
        <Col xs={20}>
          <Table
            loading={isLoading}
            autoHeight={true}
            bordered={false}
            data={sessions}
            onRowClick={rowData => {
              console.log(rowData);
            }}
          >
            <Column width={300} align="center" fixed>
              <HeaderCell>Id</HeaderCell>
              <Cell dataKey="id" />
            </Column>

            <Column flexGrow={1}>
              <HeaderCell>User agent</HeaderCell>
              <Cell dataKey="userAgent" />
            </Column>

            <Column fixed>
              <HeaderCell>...</HeaderCell>
              <Cell style={{ padding: '6px' }}>
                {rowData => (
                  <Link to={`/orgs/${orgId}/user-monitoring/sessions/${rowData.id}?${searchParams.toString()}`}>Detail</Link>
                )}
              </Cell>
            </Column>
          </Table>
        </Col>

      </Row>
    </Grid>
  )
}

export default Sessions