import React from 'react';
import { signIn, SignInInput } from 'aws-amplify/auth';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import Main from './modules/Main';
import {
  Authenticator, Button,
  Heading,
  useAuthenticator,
  useTheme,
  View
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import "./index.less";
import CreateOrganization from './modules/CreateOrganization';
import Home from './modules/Home';
import LogoFull from './core/assets/images/logo-full.svg'
import WithoutOrganizations from './modules/StaticPages/WithoutOrganizations';
import NotFoundPage from './modules/StaticPages/404';
import UserMonitoring from "./modules/UserMonitoring";
import Performance from "./modules/UserMonitoring/Performance";
import Sessions from "./modules/UserMonitoring/Sessions";
import WithoutProject from "./modules/UserMonitoring/WithoutProject";
import CreateProject from "./modules/UserMonitoring/CreateProject";
import ProtectedRoute from "./core/components/Route/ProtectedRoute";
import OrganizationRoute from "./core/components/Route/OrganizationRoute";
import UserMonitoringRoute from './core/components/Route/UserMonitoringRoute';
import CreateForm from './modules/CreateOrganization/CreateForm';
import Checkout from './modules/CreateOrganization/Checkout';
import Profile from './modules/Profile';
import SessionReplay from './modules/UserMonitoring/Sessions/SessionDetail/Replay';
import SessionHeatmap from './modules/UserMonitoring/Sessions/SessionDetail/Heatmap';
import Cloudx from './modules/Cloudx';
import Infras from './modules/Cloudx/Infras';
import InfraView from './modules/Cloudx/InfraView';
import TaskOutputs from './modules/Cloudx/TaskOutputs';
import Providers from './modules/Cloudx/Providers';
import Webhooks from './modules/Cloudx/Webhooks';

const components = {
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          level={3}
        >
          <View textAlign="center" padding="24px 24px 0px 24px">
            <div style={{marginBottom: "24px", marginTop: "20px"}}>
              <img src={LogoFull} width="136" />

            </div>
            <div className="sigin-h1">
              Boas-vindas
            </div>
            <div className="sigin-h2">
              Estamos felizes por você aqui.🤘 <br />
              Continue o login com sua conta abaixo
            </div>
          </View>
        </Heading>
      );
    },
    Footer() {
      const { toForgotPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toForgotPassword}
            size="small"
            variation="link"
            color="#A39AAC"
            fontFamily="Inter"
            marginBottom="24px"
          >
            Esqueceu sua senha?
          </Button>
        </View>
      );
    },
  },
}

const formFields = {
  signIn: {
    username: {
      placeholder: 'Ex: nome@mail.com',
      isRequired: true,
      label: 'E-mail:'
    },
    password: {
      placeholder: 'Insira sua senha'
    }
  },
}

const queryClient = new QueryClient();

const AppRoutes = () => {
  const { authStatus } = useAuthenticator()

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={
          <ProtectedRoute>
            <Main />
          </ProtectedRoute>
        }>
          <Route path='orgs/manage' element={<CreateOrganization />}>
            <Route path='create' index element={<CreateForm />} />
            <Route path=':orgId/checkout' element={<Checkout />} />
            <Route path=':orgId/update' element={<CreateForm />} />
          </Route>
          <Route path='without-organizations' element={<WithoutOrganizations />} />
          
        </Route>
        <Route path='/orgs/:orgId' element={
          <OrganizationRoute>
            <Main />
          </OrganizationRoute>
        } >
          <Route index element={<Home />} />
          <Route path='profile' element={<Profile />} />
          <Route path='user-monitoring' element={<UserMonitoring />}>
            <Route path='' index element={<UserMonitoringRoute><Performance /></UserMonitoringRoute>} />
            <Route path='sessions' index element={<UserMonitoringRoute><Sessions /></UserMonitoringRoute>} />
            <Route path='sessions/:sessionId' index element={<UserMonitoringRoute><SessionReplay /></UserMonitoringRoute>} />
            <Route path='sessions/:sessionId/heatmap' index element={<UserMonitoringRoute><SessionHeatmap /></UserMonitoringRoute>} />
            <Route path='create-project' index element={<CreateProject />} />
            <Route path='without-project' index element={<WithoutProject />} />
          </Route>
          <Route path='cloudx' element={<Cloudx />}>
            <Route path='workspaces/:workspaceId' index element={<Infras/>} />
            <Route path='workspaces/:workspaceId/infras/:infraId' index element={<InfraView/>} />
            <Route path='workspaces/:workspaceId/task-outputs' index element={<TaskOutputs/>} />
            <Route path='workspaces/:workspaceId/providers' index element={<Providers/>} />
            <Route path='workspaces/:workspaceId/webhooks' index element={<Webhooks/>} />
          </Route>
          <Route path='*' element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
const App = () => {

  const services = {
    async handleSignIn(input: SignInInput) {
      let { username, password } = input;

      return signIn({username, password})
    },
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Authenticator services={services} loginMechanism={'email'} formFields={formFields} components={components} hideSignUp={true}>
        <AppRoutes />
      </Authenticator>
    </QueryClientProvider>
  )
}

export default App;
