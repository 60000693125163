import React, { useCallback, useEffect, useRef, useState } from "react";
import rrwebPlayer from 'rrweb-player';
import { useParams, useSearchParams } from "react-router-dom";
import { useCustomQuery } from "../../../../core/hooks/use-custom-query";
import { userMonitoringApi } from "../../../../core/http/user-monitoring";
import 'rrweb-player/dist/style.css';
import { Col, Grid, List, Nav, Panel, Row } from "rsuite";
import { EventType, IncrementalSource } from "rrweb";
import dayjs from 'dayjs'


const SessionHeatmap = () => {
  const { orgId, sessionId } = useParams()
  const [searchParams] = useSearchParams()
  const { data: session } = useCustomQuery('sessions', () => userMonitoringApi.getProjectSessionDetail(orgId || "", searchParams.get('project') || "", sessionId || ""))


  return (
    <div style={{ padding: "30px", height: "calc(100vh - 500px)" }}>
      
      <Grid fluid>
        <Row>
          <Col xs={2}>
            <Nav vertical appearance="subtle">
              <Nav.Item>Replay</Nav.Item>
              <Nav.Item>Heatmap</Nav.Item>
            </Nav>
          </Col>
          <Col xs={22}>
           
          </Col>
        </Row>
      </Grid>
      
    </div>
  )
}

export default SessionHeatmap;