import { httpAuthClient } from "./client"

const getOrg = async (orgid: string) => {
  const httpClient = await httpAuthClient()
  const {data} = await httpClient.get(`/orgs/${orgid}`)
  return data
}

const getOrgs = async () => {
  const httpClient = await httpAuthClient()
  const {data} = await httpClient.get(`/orgs`)
  return data
}


const createOrg = async (newData: any) => {
  const httpClient = await httpAuthClient()
  const {data} = await httpClient.post(`/orgs`, newData)
  return data
}

const updateOrg = async (orgId: string, newData: any) => {
  const httpClient = await httpAuthClient()
  const {data} = await httpClient.put(`/orgs/${orgId}`, newData)
  return data
}

export const orgApi = {
  getOrgs,
  getOrg,
  createOrg,
  updateOrg,
}