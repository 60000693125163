import React from 'react'

const Providers = () => {
  return (
    <>
      <h1>Providers</h1>
    </>
  )
}

export default Providers