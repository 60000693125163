import React, {useCallback, useEffect, useRef, useState} from 'react'
import {NavLink, Outlet, matchPath, useLocation, useMatch, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Nav, Navbar, SelectPicker, DateRangePicker, Breadcrumb} from "rsuite";
import "./style.less"
import { useCustomQuery } from '../../core/hooks/use-custom-query';
import { cloudxApi } from '../../core/http/cloudx';
import { useQuery } from 'react-query';



const Cloudx = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { orgId, workspaceId, infraId } = useParams()
  const [searchParams, setSearchParams ] = useSearchParams()
  const [currentProduct, setCurrentProduct] = useState<string>("")
  const [workspaces, setWorkspaces] = useState<any>([])
  const { isFetching, data, refetch } = useCustomQuery("cloudxWorkspaces", () => cloudxApi.getWorkspaces(orgId || ""), {
    retry: 0,
    onSuccess(data: any) {

    },
    onError(err) {
      setWorkspaces([{
        id: "a752d8c6-ce00-42fa-9fcb-fa6f0bdb1a6e",
        name: "fake-workspace-1"
      }])
    }
  })
  const [dateTimeRange, setDateTimeRange] = useState([new Date('2022-02-01 00:00:00'), new Date('2022-05-01 23:59:59')])


  useEffect(() => {
    if (currentProduct === "") return
    setSearchParams({ workspace: currentProduct })
  }, [currentProduct]);

  // useEffect(() => {
  //   setProjects(data)
  // }, [data]);

  useEffect(() => {
    if (!isFetching && workspaces?.length <= 0 && !workspaceId) {
      navigate(`/orgs/${orgId}/cloudx/without-workspace`)
      return
    }

    if (!workspaceId && !isFetching && workspaces?.length === 1) {
      // setSearchParams({ startPeriod: dateTimeRange[0].toISOString(), endPeriod: dateTimeRange[0].toISOString() })
      navigate(`/orgs/${orgId}/cloudx/workspaces/${workspaces[0].id}`)
      return
    }
  }, [workspaces, isFetching]);

  // useEffect(() => {
  //   refetch()
  // }, [location]);

  const handleSelectProject = useCallback((value: string) => {
    if (value === "create-workspace") {
      setCurrentProduct("")
      navigate(`/orgs/${orgId}/cloudx/create-workspace`)
      return
    }
    setCurrentProduct(value)
  }, [])

  return (
    <div className="cloudx">
      <div className="cloudx__header">
        <Navbar appearance="subtle" style={{ background: "#fff" }}>
          <Nav>
            <Nav.Item eventKey="1">
              <SelectPicker value={workspaceId} style={{ width: 224 }} cleanable={false} loading={isFetching} data={workspaces?.map((workspace: any) => ({
                label: workspace?.name, value: workspace?.id
              }))} />
            </Nav.Item>
            <Nav.Item as={NavLink} to={`/orgs/${orgId}/cloudx/workspaces/${workspaceId}?${searchParams.toString()}`} end>
              Infras
            </Nav.Item>
            <Nav.Item as={NavLink} to={`/orgs/${orgId}/cloudx/workspaces/${workspaceId}/task-outputs?${searchParams.toString()}`} end>
              Task outputs
            </Nav.Item>
            <Nav.Item as={NavLink} to={`/orgs/${orgId}/cloudx/workspaces/${workspaceId}/providers?${searchParams.toString()}`} end>
              Providers
            </Nav.Item>
            <Nav.Item as={NavLink} to={`/orgs/${orgId}/cloudx/workspaces/${workspaceId}/webhooks?${searchParams.toString()}`} end>
              Webhooks
            </Nav.Item>
          </Nav>
          <Nav pullRight>
            {/* <Nav.Item>
              <DateRangePicker
                placement="bottomEnd"
                format="yyyy-MM-dd hh:mm aa"
                value={[new Date(searchParams.get("startPeriod") || dateTimeRange[0]), new Date(searchParams.get("endPeriod") || dateTimeRange[1])]}
                defaultCalendarValue={[dateTimeRange[0], dateTimeRange[1]]}
              />
            </Nav.Item> */}
            <Nav.Item as={NavLink} to={`/orgs/${orgId}/cloudx/settings?${searchParams.toString()}`} end>Settings</Nav.Item>
          </Nav>
        </Navbar>
        {workspaces?.length >= 1 && (
        <div style={{ padding: "20px", background: "#fff", borderTop: "1px solid #F0EEF1", borderBottom: "1px solid #F0EEF1" }}>
          <Breadcrumb style={{marginBottom: 0}} separator=">">
            {[
              {path: `/orgs/${orgId}/cloudx/*`, label: 'Organizations'},
              {path: `/orgs/${orgId}/cloudx/*`, label: orgId, isLink: true},
              {path: `/orgs/${orgId}/cloudx/workspaces/${workspaceId}/*`, label: 'Workspaces'},
              {path: `/orgs/${orgId}/cloudx/workspaces/${workspaceId}/*`, label: workspaceId, isLink: true},
              {path: `/orgs/${orgId}/cloudx/workspaces/${workspaceId}/infras/${infraId}/*`, label: 'Infras'},
              {path: `/orgs/${orgId}/cloudx/workspaces/${workspaceId}/infras/${infraId}/*`, label: infraId, isLink: true},
            ].filter((item: any) => matchPath(item?.path, location.pathname)).map((item: any) =>
              !item?.isLink ? (
                <Breadcrumb.Item>
                  {item?.label}
                </Breadcrumb.Item>
              ) : (
                <Breadcrumb.Item as={NavLink} to={item.path.replace(/\/\*$/, `?${searchParams.toString()}`)}>
                  {item?.label}
                </Breadcrumb.Item>
              )
            )}
          </Breadcrumb>
        </div>
        )}
      </div>
      <Outlet />
    </div>
  )
}

export  default Cloudx