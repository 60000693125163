import React from 'react'
import { ReactComponent as NotFound } from '../../core/assets/images/undraw_not_found.svg'
import { Link } from 'react-router-dom'


const NotFoundPage = () => {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center' style={{ marginTop: '5rem' }}>
      <NotFound width={300} height={400} />
      <p className='lead text-center'>
        Page not found 
      </p>
    </div>
  )
}

export default NotFoundPage