import { getCurrentUser } from 'aws-amplify/auth'
import React from 'react'
import { useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import { getCurrentSession } from '../../core/helpers/auth'
import { httpAuthClient } from '../../core/http/client'
import { orgApi } from '../../core/http/orgs'
import { Message } from 'rsuite'

const Home = () => {
  const { orgId } = useParams<{ orgId: string }>()
  const { status, data, error, isFetching, refetch } = useQuery('currentOrg', () => orgApi.getOrg(orgId || ""));

  return (
    <>
      {data?.status === "checkout_pending" && (
        <Message showIcon type="warning" header="Warning">
          Your organization is pending for checkout. Please <Link to={`/orgs/manage/${orgId}/checkout`}>checkout</Link> to continue using the app.  
        </Message>
      )}
    </>
  )
}

export default Home