import { useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ReactComponent as WhiteLogoSVG } from "../../core/assets/images/white-logo.svg";
import { ReactComponent as Grid2Icon } from "../../core/assets/icons/grid-2.svg";
import { ReactComponent as AWSLambdaIcon } from "../../core/assets/icons/aws-lambda.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "react-avatar";
import { Sidenav, Nav, InputPicker, SelectPicker } from "rsuite";
import Icon from "../../core/components/Icon";
import InviteUser from "../InviteUser";

const CustomToggle = (props: any, ref: any) => {
  const { currentUser, user } = props;
  return (
    <div
      {...props}
      ref={ref}
      style={{
        display: "flex",
        padding: "12px 16px",
        borderTop: "1px solid #29252D",
      }}
    >
      <Avatar
        size="32px"
        name={`${currentUser?.firstName} ${currentUser?.lastName}`}
        round="50%"
      />
      <div style={{ marginLeft: "8px" }}>
        <div className="sidebar-logo-title" style={{ fontSize: "12px" }}>
          {`${currentUser?.firstName} ${currentUser?.lastName}`}
        </div>
        <div style={{ fontSize: "10px", color: "#B8B1BF" }}>
          {currentUser?.email}
        </div>
      </div>
    </div>
  );
};

const Sidebar = ({ items, currentUser, isLoading, organizations }: any) => {
  const { user, signOut } = useAuthenticator();
  const location = useLocation();
  const navigate = useNavigate();
  const { orgId } = useParams();
  const [searchParams] = useSearchParams();
  const [selectedKey, selectKey] = useState<string | null>();

  return (
    <div className="main__sidebar">
      <Sidenav appearance="subtle">
        <Sidenav.Header>
          <div style={{ display: "flex", padding: "12px 16px" }}>
            <div
              style={{
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
                background:
                  "linear-gradient(222deg, rgb(75, 14, 139) 27.8%, rgb(151, 70, 236) 91.26%)",
              }}
            >
              <WhiteLogoSVG />
            </div>
            <div style={{ padding: "5px"}}>
              <SelectPicker cleanable={false} value={orgId || ""} placeholder="Select an org" appearance="subtle" data={organizations?.map((org: any) => ({ label: org?.organization?.name, value: org?.organization?.id }))} />

            </div>
          </div>
        </Sidenav.Header>

        <Sidenav.Body>
          <Nav appearance="subtle">
            <Nav.Item
              eventKey="2"
              as={NavLink}
              end
              to={orgId ? `/orgs/${orgId}` : `/`}
              icon={<Icon svg={Grid2Icon} />}
            >
              Visão geral
            </Nav.Item>
            {orgId && (
              <>
                {/* <Nav.Item
                panel
                style={{
                  padding: "15px 20px",
                  color: "#7A7184",
                  fontSize: "12px",
                }}
                >
                  User experience
                </Nav.Item> */}
                <Nav.Menu
                  placement="rightStart"
                  eventKey="4-5"
                  title="User monitoring"
                  icon={
                    <FontAwesomeIcon
                      icon={["far", "face-smile"]}
                      style={{ marginRight: "20px" }}
                    />
                  }
                >
                  <Nav.Item
                    eventKey="4-5-1"
                    as={NavLink}
                    to={`/orgs/${orgId}/user-monitoring?${searchParams.toString()}`}
                    end
                    icon={<Icon faIcon={["fas", "fire"]} />}
                  >
                    Performance
                  </Nav.Item>
                  <Nav.Item
                    eventKey="4-5-2"
                    as={NavLink}
                    to={`/orgs/${orgId}/user-monitoring/sessions?${searchParams.toString()}`}
                    end
                    icon={<Icon faIcon={["fas", "play"]} />}
                  >
                    Sessions
                  </Nav.Item>
                </Nav.Menu>
                <Nav.Item
                  eventKey="2"
                  as={NavLink}
                  end
                  to={`/orgs/${orgId}/serverless`}
                  icon={<Icon svg={AWSLambdaIcon} />}
                >
                  Serverless
                </Nav.Item>
                <Nav.Item
                  eventKey="2"
                  as={NavLink}
                  end
                  to={`/orgs/${orgId}/cloudx`}
                  style={{ paddingLeft: "20px" }}
                  icon={
                    <FontAwesomeIcon
                      icon={["fas", "cloud"]}
                      style={{ marginRight: "20px" }}
                    />
                  }
                >
                  Cloudx
                </Nav.Item>
                <Nav.Item
                  eventKey="2"
                  as={NavLink}
                  end
                  to={`/orgs/${orgId}/circlerr`}
                  style={{ paddingLeft: "20px" }}
                  icon={
                    <FontAwesomeIcon
                      icon={["fas", "circle-notch"]}
                      style={{ marginRight: "20px" }}
                    />
                  }
                >
                  Circlerr
                </Nav.Item>
              </>
              )}
            
          </Nav>
        </Sidenav.Body>
      </Sidenav>
      <Nav
        style={{
          width: "240px",
          position: "absolute",
          bottom: 0,
        }}
      >
        <Nav.Menu
          renderToggle={(props, ref) =>
            CustomToggle({ ...props, currentUser, user }, ref)
          }
          placement="rightEnd"
          style={{
            width: "100%",
          }}
        >
          <InviteUser />
          <Nav.Item
            as={NavLink}
            end
            to={`/orgs/${orgId}/profile`}
          >
            Profile
          </Nav.Item>
          <Nav.Item divider />
          <Nav.Item className="rs-sidenav-item" onClick={signOut}>
            Sign out
          </Nav.Item>
        </Nav.Menu>
      </Nav>
    </div>
  );
};

export default Sidebar;
