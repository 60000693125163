import React from 'react'
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import ProtectedRoute from './ProtectedRoute';

const UserMonitoringRoute = ({ children }: any) => {
  const { orgId } = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()



  if (!searchParams.has("project")) {
    navigate(`/orgs/${orgId}/user-monitoring/without-project`)
  }

  return <ProtectedRoute>{children}</ProtectedRoute>
}

export default UserMonitoringRoute