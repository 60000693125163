import {
  faFaceSmile,
} from "@fortawesome/free-regular-svg-icons"
import {
  faPlay,
  faCaretDown,
  faCaretUp,
  faArrowLeft,
  faLifeRing,
  faBook,
  faFire,
  faPlus,
  faCloud,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons"
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(
  faFaceSmile,
  faPlay,
  faCaretDown,
  faCaretUp,
  faLifeRing,
  faBook,
  faArrowLeft,
  faFire,
  faPlus,
  faCloud,
  faCircleNotch,
)