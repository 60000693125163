import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import {ButtonToolbar, Form, Button, Input, Grid, Row, Col, Container, Content, Message, Modal} from "rsuite";
import Products from "./Products";
import { useMutation } from "react-query";
import { orgApi } from "../../core/http/orgs";
import { useNavigate, useParams } from "react-router-dom";
import { useCustomQuery } from "../../core/hooks/use-custom-query";

const Textarea = React.forwardRef((props: any, ref) => <Input {...props} as="textarea" ref={ref} />);

const StartFreeTrial = ({ isLoading, onStartFreeTrial }: any) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    onStartFreeTrial()
    setOpen(false)
  };

  return (
    <>
      <Button appearance="ghost" onClick={handleOpen}>Start free trial</Button>
      <Modal backdrop="static" role="alertdialog" open={open} onClose={handleClose} size="xs">
        <Modal.Body>
          Are you sure? You can start free trial only for one organization.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const CreateForm = ({ organization, onChangeStep }: any) => {
  const navigate = useNavigate()
  const { register, formState: { errors, isValid, isDirty }, getValues, handleSubmit, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      name: organization?.name || "",
      description: organization?.description || "",  
    }
  })
  const { orgId } = useParams()
  const [cart, setCart] = useState<any[]>([])
  const { data: org, isLoading, refetch } = useCustomQuery("organization", () =>{
    return orgApi.getOrg(orgId || "")
  });
  const createOrganizationCheckout = useMutation(async (newData: any) => {
    const createdOrg = await orgApi.createOrg(newData)
    return createdOrg
  }, {
    onSuccess: (data: any) => {
      if (data?.status === "checkout_pending") {
        navigate(`/orgs/manage/${data?.id}/checkout`)
        return
      }

      if (data?.status === "active") {
        navigate(`/orgs/${data?.id}`)
        return
      }
    }
  })
  const updateOrganizationCheckout = useMutation(async (newData: any) => {
    const updatedOrg = await orgApi.updateOrg(orgId || "", newData)
    return updatedOrg
  }, {
    onSuccess: (data: any) => {
      if (data?.status === "checkout_pending") {
        navigate(`/orgs/manage/${data?.id}/checkout`)
        return
      }

      if (data?.status === "active") {
        navigate(`/orgs/${data?.id}`)
        return
      }
    }
  })

  useEffect(() => {
    if (orgId) {
      setValue("name", org?.name)
      setValue("description", org?.description)
      setCart(org?.checkout?.products?.map((prod: any) => ({
        id: prod?.productId,
        priceId: prod?.priceId,
      })))
    }
  }, [org, setValue])

  const  [products, setProducts] = useState<any>({
    products: [],
    totalValue: 0
  })

  const handleStartFreeTrial = useCallback(() => {
    if (orgId) {
      updateOrganizationCheckout.mutate({
        ...getValues(),
        startFreeTrial: true,
      })
      return
    }

    createOrganizationCheckout.mutate({
      ...getValues(),
      startFreeTrial: true,
    })
  }, [])

  const onSubmit = (data: any) => {
    if (orgId) {
      updateOrganizationCheckout.mutate({
        ...data,
        checkoutProducts: products?.products
      })
      return
    }

    createOrganizationCheckout.mutate({
      ...data,
      checkoutProducts: products?.products
    })
  }

  return (
    <Container>
      <Message showIcon>
        Start a free trial for 14 days. No credit card required. <StartFreeTrial isLoading={createOrganizationCheckout.isLoading || updateOrganizationCheckout.isLoading} onStartFreeTrial={handleStartFreeTrial} />
      </Message>
    <Content style={{ padding: "10px" }}>
    <Grid fluid>
      <Row>
        <Col xs={8}>
          <Form fluid>
            <Form.Group controlId="name">
              <Form.ControlLabel>Name</Form.ControlLabel>
              <Form.Control
                {...register("name", { required: "Organization name is required"  })}
                errorMessage={errors?.name?.message?.toString()} errorPlacement="bottomStart" />
            </Form.Group>
            <Form.Group controlId="textarea">
              <Form.ControlLabel>Description</Form.ControlLabel>
              <Form.Control {...register("description", { required: false })} rows={5} name="textarea" accepter={Textarea} />
            </Form.Group>
            <Form.Group>
              <ButtonToolbar>
                <Button
                  appearance="primary"
                  onClick={handleSubmit(onSubmit)}
                  loading={createOrganizationCheckout.isLoading || updateOrganizationCheckout.isLoading}
                  disabled={products?.products?.length === 0 || !isValid}
                >
                  Revisar pedido (R$ {products?.totalValue})
                </Button>
              </ButtonToolbar>
            </Form.Group>
          </Form>
        </Col>
        <Col xs={16} style={{ padding: "0px 50px" }}>
          <Products 
            products={cart}
            onSelectProducts={(data: any) => setProducts(data)}
          />
        </Col>
      </Row>
    </Grid>
    </Content>
    </Container>
  )
}

export default CreateForm