import React, {useCallback, useEffect, useRef, useState} from 'react'
import {NavLink, Outlet, matchPath, useLocation, useMatch, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Nav, Navbar, SelectPicker, DateRangePicker, Breadcrumb} from "rsuite";
import "./style.less"
import { useCustomQuery } from '../../core/hooks/use-custom-query';
import { userMonitoringApi } from '../../core/http/user-monitoring';



const UserMonitoring = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { orgId, sessionId } = useParams()
  const [searchParams, setSearchParams ] = useSearchParams()
  const [currentProduct, setCurrentProduct] = useState<string>("")
  const { isFetching, data, refetch } = useCustomQuery("userMonitoringProjects", () => userMonitoringApi.getProjects(orgId || ""))
  const [projects, setProjects] = useState<any>([])
  const [dateTimeRange, setDateTimeRange] = useState([new Date('2022-02-01 00:00:00'), new Date('2022-05-01 23:59:59')])


  useEffect(() => {
    if (currentProduct === "") return
    setSearchParams({ project: currentProduct })
  }, [currentProduct]);

  useEffect(() => {
    setProjects(data)
  }, [data]);

  useEffect(() => {
    if (!isFetching && projects?.length <= 0) {
      navigate(`/orgs/${orgId}/user-monitoring/without-project`)
      return
    }

    if (!isFetching && projects?.length === 1) {
      setSearchParams({ project: projects[0]?.id, startPeriod: dateTimeRange[0].toISOString(), endPeriod: dateTimeRange[0].toISOString() })
      return
    }
  }, [projects, isFetching]);

  // useEffect(() => {
  //   refetch()
  // }, [location]);

  const handleSelectProject = useCallback((value: string) => {
    if (value === "create-project") {
      setCurrentProduct("")
      navigate(`/orgs/${orgId}/user-monitoring/create-project`)
      return
    }
    setCurrentProduct(value)
  }, [])

  return (
    <div className="user-monitoring">
      <div className="user-monitoring__header">
        <Navbar appearance="subtle" style={{ background: "#fff" }}>
          <Nav>
            <Nav.Item eventKey="1">
              <SelectPicker value={searchParams.get("project")} style={{ width: 224 }} cleanable={false} loading={isFetching} data={projects?.map((project: any) => ({
                label: project?.name, value: project?.id
              }))} />
            </Nav.Item>
            <Nav.Item as={NavLink} to={`/orgs/${orgId}/user-monitoring?${searchParams.toString()}`} end>
              Performance
            </Nav.Item>
            <Nav.Item as={NavLink} to={`/orgs/${orgId}/user-monitoring/sessions?${searchParams.toString()}`} end>
              Sessions
            </Nav.Item>
          </Nav>
          <Nav pullRight>
            <Nav.Item>
              <DateRangePicker
                placement="bottomEnd"
                format="yyyy-MM-dd hh:mm aa"
                value={[new Date(searchParams.get("startPeriod") || dateTimeRange[0]), new Date(searchParams.get("endPeriod") || dateTimeRange[1])]}
                defaultCalendarValue={[dateTimeRange[0], dateTimeRange[1]]}
              />
            </Nav.Item>
            <Nav.Item>Settings</Nav.Item>
          </Nav>
        </Navbar>
        {projects?.length >= 1 && (
        <div style={{ padding: "20px", background: "#fff", borderTop: "1px solid #F0EEF1", borderBottom: "1px solid #F0EEF1" }}>
          <Breadcrumb style={{marginBottom: 0}} separator=">">
            {[
              {path: `/orgs/${orgId}/user-monitoring/*`, label: 'Organizations'},
              {path: `/orgs/${orgId}/user-monitoring/*`, label: orgId, isLink: true},
              {path: `/orgs/${orgId}/user-monitoring/sessions/*`, label: 'Sessions'},
              {path: `/orgs/${orgId}/user-monitoring/sessions/${sessionId}/*`, label: sessionId, isLink: true},
              {path: `/orgs/${orgId}/user-monitoring/sessions/${sessionId}/*`, label: "Replay"},
            ].filter((item: any) => matchPath(item?.path, location.pathname)).map((item: any) =>
              !item?.isLink ? (
                <Breadcrumb.Item>
                  {item?.label}
                </Breadcrumb.Item>
              ) : (
                <Breadcrumb.Item as={NavLink} to={item.path.replace(/\/\*$/, `?${searchParams.toString()}`)}>
                  {item?.label}
                </Breadcrumb.Item>
              )
            )}
            {/* <Breadcrumb.Item>
              Organizations
            </Breadcrumb.Item>
            <Breadcrumb.Item as={NavLink} to={`/orgs/${orgId}/user-monitoring?${searchParams.toString()}`}>
              {orgId}
            </Breadcrumb.Item>

            {matchPath(`/orgs/${orgId}/user-monitoring/sessions`, location.pathname) && (
              <Breadcrumb.Item>
                Sessions
              </Breadcrumb.Item>
            )}
            

            {matchPath(`/orgs/${orgId}/user-monitoring/sessions/:sessionId`, location.pathname) && sessionId && (
              <>
              <Breadcrumb.Item>
                Sessions
              </Breadcrumb.Item>
              <Breadcrumb.Item as={NavLink} to={`/orgs/${orgId}/user-monitoring/sessions/${sessionId}?${searchParams.toString()}`}>
                {sessionId}
              </Breadcrumb.Item>
              </>
            )} */}

            

          </Breadcrumb>
        </div>
        )}
      </div>
      {/*<Navbar className="bg-body-tertiary">*/}
      {/*  <Container fluid>*/}
      {/*    <Form className="d-flex">*/}
      {/*      /!*<Form.Select size="sm" value={searchParams.get("project") || ""} defaultValue={searchParams.get("project") || ""} onChange={e => handleSelectProject(e.target.value)}>*!/*/}
      {/*      /!*  <option value="" selected disabled>Select a project</option>*!/*/}
      {/*      /!*  { projects?.map((project: any) => (*!/*/}
      {/*      /!*    <option value="768e101b-f4e4-49ae-9b33-941eee0559a3">{project?.name}</option>*!/*/}
      {/*      /!*  )) }*!/*/}
      {/*      /!*  <hr />*!/*/}
      {/*      /!*  <option value="create-project"><FontAwesomeIcon icon={["fas", "plus"]}/> Create a project</option>*!/*/}
      {/*      /!*</Form.Select>*!/*/}
      {/*    </Form>*/}
      {/*  </Container>*/}
      {/*  <DatePicker />*/}
      {/*</Navbar>*/}
      {/*<Nav className="p-2" variant="underline" defaultActiveKey="/home">*/}
      {/*  <Nav.Item>*/}
      {/*    <NavLink className="nav-link" to={`/orgs/${orgId}/user-monitoring?${searchParams.toString()}`} end>Performance</NavLink>*/}
      {/*  </Nav.Item>*/}
      {/*  <Nav.Item>*/}
      {/*    <NavLink className="nav-link" to={`/orgs/${orgId}/user-monitoring/sessions?${searchParams.toString()}`} end>Sessions</NavLink>*/}
      {/*  </Nav.Item>*/}
      {/*</Nav>*/}
      <Outlet />
    </div>
  )
}

export  default UserMonitoring