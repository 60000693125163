import { httpAuthClient } from "./client"

const list = async () => {
  const httpClient = await httpAuthClient()
  const {data} = await httpClient.get(`/products`)
  return data
}



export const productApi = {
  list
}