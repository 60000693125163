import React, {useEffect, useRef, useState} from 'react'
import { ReactComponent as Grid2Icon } from '../../core/assets/icons/grid-2.svg'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const MENU_ITEMS = [
  {
    label: "Visao geral",
    icon: <Grid2Icon />,
    path: "",
  },
  {
    label: "Experiência do usuário",
    type: "category",
    items: [
      {
        label: "User monitoring",
        icon: <FontAwesomeIcon icon={["far", "face-smile"]} />,
        path: "user-monitoring",
        items: [
          { label: "Performance", preserveSearch: true, path: "user-monitoring", icon: <FontAwesomeIcon icon={["fas", "fire"]} /> },
          { label: "Sessions", preserveSearch: true, path: "user-monitoring/sessions", icon: <FontAwesomeIcon icon={["fas", "play"]} /> }
        ]
      }
    ]
  }
]