import axios from "axios"
import { getCurrentSession } from "../helpers/auth"

const baseURL = "https://api.insightfy.co"
// const baseURL = "http://localhost:3002"
export const httpAuthClient = async () => {
  const { accessToken } = await getCurrentSession()

  return axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}