import { httpAuthClient } from "./client"

const getProjects = async (orgId: string) => {
  const httpClient = await httpAuthClient()
  const {data} = await httpClient.get(`/orgs/${orgId}/user-monitoring/projects`)
  return data
}

const createProject = async (orgId: string, newData: any) => {
  const httpClient = await httpAuthClient()
  const {data} = await httpClient.post(`/orgs/${orgId}/user-monitoring/projects`, newData)
  return data
}

const getProjectSessions = async (orgId: string, projectId: string) => {
  const httpClient = await httpAuthClient()
  const {data} = await httpClient.get(`/orgs/${orgId}/user-monitoring/projects/${projectId}/sessions`)
  return data
}

const getProjectSessionDetail = async (orgId: string, projectId: string, sessionId: string) => {
  const httpClient = await httpAuthClient()
  const {data} = await httpClient.get(`/orgs/${orgId}/user-monitoring/projects/${projectId}/sessions/${sessionId}`)
  return data
}


export const userMonitoringApi = {
  getProjects,
  createProject,
  getProjectSessions,
  getProjectSessionDetail,
}