import React, {useEffect, useState} from 'react'
import {Link, Outlet, useParams, useSearchParams} from "react-router-dom";
import { ReactComponent as BlankCanvas } from '../../core/assets/images/undraw_blank_canvas.svg'
import { Stack } from 'rsuite';


const WithoutProject = () => {
  const { orgId } = useParams()

  return (
    <Stack direction={"column"} justifyContent={"center"} style={{ marginTop: '5rem' }}>
      <BlankCanvas width={300} height={400} />
      <p className='lead text-center' style={{ color: "#6c757d", fontSize: "18px", textAlign: "center" }}>
      You don't have any project. <br/>
          Create your first project to getting started <Link to={`/orgs/${orgId}/user-monitoring/create-project`}>right now</Link>
      </p>
    </Stack>
  )
}

export  default WithoutProject