import React from 'react'
import { ReactComponent as BlankCanvas } from '../../core/assets/images/undraw_blank_canvas.svg'
import { Link } from 'react-router-dom'
import {FlexboxGrid, Stack} from "rsuite";


const WithoutOrganizations = () => {
  return (
    <Stack direction={"column"} justifyContent={"center"} style={{ marginTop: '5rem' }}>
      <BlankCanvas width={300} height={400} />
      <p className='lead text-center' style={{ color: "#6c757d", fontSize: "18px", textAlign: "center" }}>
        You don't have any organization. <br/>
        Ask someone to add you to one or create yours <Link to="/orgs/manage/create">right now</Link>
      </p>
    </Stack>
  )
}

export default WithoutOrganizations