import { useMutation, useQuery } from "react-query"
import { userApi } from "../../core/http/users"
import { getCurrentUser } from "aws-amplify/auth"
import { useCustomQuery } from "../../core/hooks/use-custom-query"
import { orgApi } from "../../core/http/orgs"

export const useGetCurrentUser = () => useCustomQuery("user", async() => {
  const loggedUser = await getCurrentUser()
  const currentUser = await userApi.getUser(loggedUser.userId)
  return currentUser
}, { retry: 0, refetchOnWindowFocus: false })

export const useOrganizations = () => useCustomQuery("organizations", async() => {
  const loggedUser = await getCurrentUser()
  const currentUser = await orgApi.getOrgs()
  return currentUser
}, { retry: 0, refetchOnWindowFocus: false })