import { httpAuthClient } from "./client"

const getWorkspaces = async (orgId: string) => {
  const httpClient = await httpAuthClient()
  const {data} = await httpClient.get(`/orgs/${orgId}/cloudx/workspaces`)
  return data
}

const getInfras = async (orgId: string, workspaceId: string) => {
  const httpClient = await httpAuthClient()
  const {data} = await httpClient.get(`/orgs/${orgId}/cloudx/workspaces/${workspaceId}/infras`)
  return data
}

const getInfra = async (orgId: string, workspaceId: string, infraId: string) => {
  const httpClient = await httpAuthClient()
  const {data} = await httpClient.get(`/orgs/${orgId}/cloudx/workspaces/${workspaceId}/infras/${infraId}`)
  return data
}

export const cloudxApi = {
  getWorkspaces,
  getInfras,
  getInfra,
}