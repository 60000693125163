import React, {useCallback, useEffect, useState} from 'react'
import {Link, Outlet, useNavigate, useParams, useSearchParams} from "react-router-dom";
import { ReactComponent as BlankCanvas } from '../../core/assets/images/undraw_blank_canvas.svg'
import {useForm} from "react-hook-form";
import {useMutation} from "react-query";
import { userMonitoringApi } from '../../core/http/user-monitoring';
import { Button, ButtonToolbar, Container, Content, FlexboxGrid, Form, Input, Panel } from 'rsuite';


const Textarea = React.forwardRef((props: any, ref) => <Input {...props} as="textarea" ref={ref} />);


const CreateProject = ({ onCreatedProject }: any) => {
  const { orgId } = useParams()
  const navigate = useNavigate()
  const { register, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      name: "",
      description: "",
    }
  })
  const createProject = useMutation((newData: any) => userMonitoringApi.createProject(orgId || "", newData), {
    onSuccess: (data: any) => {
      onCreatedProject()
      navigate(`/orgs/${orgId}/user-monitoring/projects?project=${data?.id}`)
    }
  })

  const onSubmit = (data: any) => {

    createProject.mutate(data)
  }


  return (
    <Container>
      <Content>
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item colspan={16}>
            <Panel header={<h3>Create a project</h3>} bordered style={{ backgroundColor: "#fff", marginTop: "20px" }}>
              <Form fluid>
                <Form.Group controlId="name">
                  <Form.ControlLabel>Name</Form.ControlLabel>
                  <Form.Control
                    {...register("name", { required: "Organization name is required"  })}
                    errorMessage={errors?.name?.message?.toString()} errorPlacement="bottomStart" />
                </Form.Group>
                <Form.Group controlId="textarea">
                  <Form.ControlLabel>Description</Form.ControlLabel>
                  <Form.Control {...register("description")} rows={5} name="textarea" accepter={Textarea} />
                </Form.Group>
                <Form.Group>
                  <ButtonToolbar>
                    <Button appearance="primary"  onClick={handleSubmit(onSubmit)}>Next</Button>
                  </ButtonToolbar>
                </Form.Group>
              </Form>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
    </Container>
  )
}

export  default CreateProject