import React from 'react'
import {useNavigate, useParams} from "react-router-dom";
import ProtectedRoute from './ProtectedRoute';

const OrganizationRoute = ({ children }: any) => {
  const { orgId } = useParams()
  const navigate = useNavigate()

  if ((!orgId || orgId === "undefined")) {
    navigate("/without-organizations")
  }

  return <ProtectedRoute>{children}</ProtectedRoute>
}

export default OrganizationRoute