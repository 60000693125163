import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query";
import { getCurrentSession } from "../../core/helpers/auth";
import { httpAuthClient } from "../../core/http/client";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useCustomQuery } from "../../core/hooks/use-custom-query";
import { orgApi } from "../../core/http/orgs";
import { Button, Panel } from "rsuite";

const stripePromise = loadStripe("pk_live_51ODIJ1IqQnMnIJfkJ5cC2CshR9xfAgJw48z5QqSO4tkHgbomfKYuNd5MKgMWYBoTAFTrAAuYud0fOJv0gjeL2yez001CMY4uUM");

const Checkout = ({ organization }: any) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { orgId } = useParams()
  const { status, data, error, isLoading, refetch } = useCustomQuery("organization", () => orgApi.getOrg(orgId || ""));

  // useEffect(() => {
  //   refetch()
  // }, [searchParams])

  return (
    <>
      {!isLoading && data?.checkout?.clientSecret && (
        <>
        <Panel bordered>
          <h4>{data?.name}</h4>
          <p>
            {data?.description}
          </p>
          <Link to={`/orgs/manage/${orgId}/update`}>Alterar</Link><br /><br />
        </Panel>
        
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{
            clientSecret: data?.checkout?.clientSecret
          }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
        </>
      )}
    </>
  )
}

export default Checkout