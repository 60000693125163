import { ReactNode, useState } from "react";
import { Modal as RModal, Button as RButton } from "rsuite";

interface ModalProps {
  title?: string;
  children: any; //ReactNode | ReactNode[] | string;
  cancelText?: string;
  confirmText?: string;
  onConfirm?: (done: Function) => void;
  disableConfirm?: boolean;
  onCancel?: () => void;
  footer?: boolean;
  trigger: ReactNode | string;
}

const Modal = ({
  title,
  children,
  cancelText = "Cancel",
  confirmText = "OK",
  onConfirm,
  disableConfirm = false,
  onCancel = () => {},
  footer = true,
  trigger: Button,
}: ModalProps) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onCancelAction = () => {
    onCancel();
    handleClose();
  };

  const onConfirmAction = () => {
    onConfirm?.(handleClose);
  };

  return (
    <div>
      <div onClick={() => setOpen(true)}>{Button}</div>
      <RModal
        backdrop={true}
        keyboard={false}
        open={open}
        onClose={handleClose}
      >
        {title && (
          <RModal.Header>
            <RModal.Title>{title}</RModal.Title>
          </RModal.Header>
        )}

        <RModal.Body>
          {children === "function" ? children() : children}
        </RModal.Body>
        {footer && (
          <RModal.Footer>
            <RButton
              onClick={onConfirmAction}
              appearance="primary"
              disabled={disableConfirm}
            >
              {confirmText}
            </RButton>
            <RButton onClick={onCancelAction} appearance="subtle">
              {cancelText}
            </RButton>
          </RModal.Footer>
        )}
      </RModal>
    </div>
  );
};

export default Modal;
