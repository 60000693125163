import React, { useEffect } from "react";
import { Badge, Button, Col, FlexboxGrid, Form, Grid, Panel, PanelGroup, Progress, Row } from "rsuite";
import Avatar from "react-avatar";
import { useGetCurrentUser } from "../Main/query";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";



const Profile = () => {
  const {orgId} = useParams()
  const { data: currentUser } = useGetCurrentUser()
  const { register, formState: { errors, isValid, isDirty }, handleSubmit, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: currentUser?.firstName || "",
      lastName: currentUser?.lastName || "",
      email: currentUser?.email || "",
    }
  })

  useEffect(() => {
    setValue("firstName", currentUser?.firstName)
    setValue("lastName", currentUser?.lastName)
    setValue("email", currentUser?.email)
  }, [currentUser])

  const onSubmit = (data: any) => {
    console.log(data)
  }

  return (
    <>
      <Grid fluid>
        <Row>
          <Col xs={16}>
            <h3>Organizations</h3>
            <PanelGroup bordered>
              {currentUser?.organizations?.map((org: any) => (
                <Panel header={(
                  <FlexboxGrid align="middle">
                    <h4 style={{ marginRight: "10px" }}>{org?.organization?.name}</h4>
                    { org?.organization?.status === "checkout_pending" && <Badge color="yellow" content="checkout_pending"/>}
                    { org?.organization?.status === "active" && <Badge color="green" content="active"/>}

                  </FlexboxGrid>
                )} key={org?.id}>
                  <p>{org?.description}</p>
                  { org?.organization?.status === "checkout_pending" && <Link to={`/orgs/create/${org?.organization?.id}/checkout`}>Finish checkout</Link>}
                  {org?.organization?.trial && (
                    <>
                      <h5>Trial period</h5>
                      <p>Days left: {dayjs(org?.organization?.trial?.endAt).diff(org?.organization?.trial?.startAt, 'days')}</p>
                      <Progress status="active"
                        percent={(dayjs(org?.organization?.trial?.endAt).diff(org?.organization?.trial?.startAt, 'days') * 100) / 14} />
                    </>
                  )}
                </Panel>
              ))}
            </PanelGroup>
          </Col>
          <Col xs={8} style={{ padding: "10px" }}>
            <FlexboxGrid justify="center">
            <Avatar name={`${currentUser?.firstName} ${currentUser?.lastName}`} round size="200px" />
            </FlexboxGrid>
            <Form fluid>
              <Form.Group controlId="name">
                <Form.ControlLabel>First name</Form.ControlLabel>
                <Form.Control {...register("firstName", { required: "First name is required" })} />
              </Form.Group>
              <Form.Group controlId="name">
                <Form.ControlLabel>Last name</Form.ControlLabel>
                <Form.Control {...register("lastName", { required: "First name is required" })} />
              </Form.Group>
              <Form.Group controlId="name">
                <Form.ControlLabel>Email</Form.ControlLabel>
                <Form.Control readOnly {...register("email", { required: "Email is required" })} />
              </Form.Group>
              <FlexboxGrid justify="end">
                <Button appearance="primary" onSubmit={handleSubmit(onSubmit)}>Save</Button>
              </FlexboxGrid>
            </Form>
          </Col>
        </Row>
      </Grid>
    </>
  )
}

export default Profile