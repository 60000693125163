import React, { useCallback, useEffect, useState } from 'react'
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import Info from './Info';
import Products from './Products';
import Checkout from './Checkout';
import {Divider, Steps} from "rsuite";
import { useMutation } from 'react-query';
import { orgApi } from '../../core/http/orgs';
import CreateForm from './CreateForm';


const CreateOrganization = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams({ step: "1" })
  const [organization, setOrganization] = useState<any>(JSON.parse(localStorage.getItem('organization') || "{}"))
  

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ height: "50px" }}>
        <h3>Create an organization</h3>
      </div>
      <Divider />
      <div style={{ width: "80%", margin: "auto", marginTop: "50px" }}>
        <Steps current={Number(searchParams.get("step")) - 1 || 0} style={{ marginBottom: "30px" }}>
          <Steps.Item title="Create" />
          <Steps.Item title="Checkout" />
        </Steps>
        <Outlet />
        {/* {Number(searchParams.get("step")) === 1 && <CreateForm organization={organization} onChangeStep={handleChangeStep} />}
        {Number(searchParams.get("step")) === 2 && <Checkout organization={organization} />} */}
      </div>

    </div>
  )
}

export default CreateOrganization