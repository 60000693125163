import React from 'react'
import {useAuthenticator} from "@aws-amplify/ui-react";
import {Loader} from "rsuite";

const ProtectedRoute = ({ children }: any) => {
  const auth = useAuthenticator()

  console.log("AUTH", auth.authStatus)

  if (auth.authStatus === 'configuring') {
    return <Loader backdrop center content="loading..." />
  }

  if (auth.authStatus !== 'authenticated') {
    return auth.signOut()
  }

  return children
}

export default ProtectedRoute