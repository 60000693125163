import React, {useEffect, useState} from 'react'
import {Nav} from "rsuite";

const Performance = () => {
  const [ xmlHttpRequests, setXMLHttpRequests ] = useState<any>([])
  const [ scripts, setScripts ] = useState<any>([])

  useEffect(() => {
    const entries = window.performance.getEntries().map(entry => entry.toJSON())

    const requests = entries.filter(entry => entry.initiatorType === "xmlhttprequest")
      .map((i: any) => ({
        ...i,
        duration: (i?.duration / 1000).toFixed(2)
      }))
    const scripts = entries.filter(entry => entry.initiatorType === "script")
      .map((i: any) => ({
        ...i,
        duration: (i?.duration / 1000).toFixed(2)
      }))


    setXMLHttpRequests(requests)
    setScripts(scripts)

  }, []);

  return (
    <div>

    </div>
  )
}

export default Performance