import React, {Suspense, useEffect, useLayoutEffect, useRef, useState} from 'react'
import { useAuthenticator, WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { useForm, SubmitHandler } from "react-hook-form"
import Sidebar from "./Sidebar";
import { useMutation, useQuery } from 'react-query';
import { httpAuthClient } from '../../core/http/client';
import { getCurrentSession } from '../../core/helpers/auth';
import { getCurrentUser } from 'aws-amplify/auth';
import {Link, Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import { MENU_ITEMS } from './menu-items';
import "./style.less"
import { userApi } from '../../core/http/users';
import { useGetCurrentUser, useOrganizations } from './query';


const CompleteSignupModal = ({ email, isOpen, isLoading, submitError, onSubmit, onSignOut }: any) => {
  const { handleSubmit, formState: { errors }, register } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      organizations: [],
      email
    }
  })

  return (
    <></>
    // <Modal
    //   show={isOpen}
    //   backdrop="static"
    //   keyboard={false}
    // >
    //   <Modal.Header>
    //     <Modal.Title>Complete your registration</Modal.Title>
    //   </Modal.Header>
    //   <Modal.Body>
    //     {submitError && (
    //       <Alert variant='danger'>
    //         An error occurred. Please contact the support
    //       </Alert>
    //     )}
    //     Hello, we are very happy to have you here, to continue we need you to complete your registration:
    //
    //   <Form className='mt-3'>
    //     <Row className='mb-3'>
    //       <Col>
    //         <Form.Control {...register('firstName', { required: "First name is required" })} placeholder="First name" />
    //         <div className='text-danger'>{errors?.firstName?.message}</div>
    //       </Col>
    //       <Col>
    //         <Form.Control {...register('lastName', { required: "Last name is required" })} placeholder="Last name" />
    //         <div className='text-danger'>{errors?.lastName?.message}</div>
    //       </Col>
    //     </Row>
    //     <Row>
    //       <Col>
    //         <Form.Control {...register('email')} placeholder="Email" readOnly disabled />
    //       </Col>
    //     </Row>
    //   </Form>
    //   </Modal.Body>
    //   <Modal.Footer>
    //     <Button variant="secondary" onClick={onSignOut}>Sign out</Button>
    //     <Button variant="primary" isLoading={isLoading} onClick={handleSubmit(onSubmit)}>Save</Button>
    //   </Modal.Footer>
    // </Modal>
  )
}

const allowedRoutes = [
  "/orgs/create",
  "/orgs/:orgId/profile"
]

const Main = () => {
  const auth = useAuthenticator()
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const [needCompleteSignup, setNeedCompleteSignup] = useState(false)
  const { status, data, error, isFetching, refetch } = useGetCurrentUser()
  const completeSignup = useMutation(userApi.createUser, {
    onSuccess: () => {
      setNeedCompleteSignup(false)
      refetch()
      navigate('/')
    }
  })


  useEffect(() => {
    if (error) {
      const { data } = (error as any)?.response
      if (data && data?.code === "USER_NOT_FOUND") {
        setNeedCompleteSignup(true)
      }
    }
    
  }, [isFetching])

  useEffect(() => {
    if (data) {
      console.log(data)
      const { organizations } = data
      if (organizations?.length === 1 && !allowedRoutes.includes(location.pathname)) {

        if (!params?.orgId) {
          navigate(`/orgs/${organizations[0].organization?.id}`)
        }

        return
      }

      if (organizations.length === 0 && !allowedRoutes.includes(location.pathname)) {
        navigate("without-organizations")
      }
    }
  }, [data])


  return (
    <div className='main' style={{display: "flex"}}>
      <Sidebar
        isLoading={isFetching}
        currentUser={data}
        organizations={data?.organizations}
        items={data?.organizations?.length === 0 ? [] : MENU_ITEMS}
      />
      <div className="main__content">
        <Outlet />
      </div>
      
      <CompleteSignupModal
        email={auth?.user?.signInDetails?.loginId}
        isOpen={auth?.user && needCompleteSignup}
        isLoading={completeSignup.isLoading}
        submitError={completeSignup?.error}
        onSignOut={auth.signOut}
        onSubmit={(newData: any) => completeSignup.mutate({
          ...newData,
          id: auth.user.username,
        })}
      />
    </div>
  )
}

export default Main
