import React, { useCallback, useEffect, useRef, useState } from "react";
import rrwebPlayer from 'rrweb-player';
import { useParams, useSearchParams } from "react-router-dom";
import { useCustomQuery } from "../../../../core/hooks/use-custom-query";
import { userMonitoringApi } from "../../../../core/http/user-monitoring";
import 'rrweb-player/dist/style.css';
import { Col, Grid, List, Nav, Panel, Row } from "rsuite";
import { EventType, IncrementalSource } from "rrweb";
import dayjs from 'dayjs'


const SessionReplay = () => {
  const [player, setPlayer] = useState<any>()
  const { orgId, sessionId } = useParams()
  const [searchParams] = useSearchParams()
  const { data: session } = useCustomQuery('sessions', () => userMonitoringApi.getProjectSessionDetail(orgId || "", searchParams.get('project') || "", sessionId || ""))
  const ref = useRef<any>(null)

  useEffect(() => {
    if (!session) return
    if (!ref.current) return
    if (session?.data?.sessionEvents?.length && session?.data?.sessionEvents?.length > 2) {
      const rrweb = new rrwebPlayer({
        target: ref.current, // customizable root element
        props: {
          maxScale: 0,
          width: 800,
          events: session?.data?.sessionEvents,
          autoPlay: false,
        },
      });

      setPlayer(rrweb)
    }
  }, [session])

  const getDuration = (sessions: any, current: any, index: number) => {
    return dayjs(current).diff(sessions[0].timestamp)
  }

  return (
    <div style={{ padding: "30px", height: "calc(100vh - 500px)" }}>
      
      <Grid fluid>
        <Row>
          <Col xs={2}>
            <Nav vertical appearance="subtle">
              <Nav.Item>Replay</Nav.Item>
              <Nav.Item>Heatmap</Nav.Item>
            </Nav>
          </Col>
          <Col xs={14}>
            <div >
            <div ref={ref}></div>
            </div>
          </Col>
          <Col xs={8}>
            <Panel header={"Events"}>
              <List bordered style={{ height: "calc(100vh - 300px)" }}>
                {session?.data?.sessionEvents?.map((sess: any, i: any) => (
                  <List.Item style={{ cursor: 'pointer' }} onClick={() => player && player.goto(getDuration(session?.data?.sessionEvents, sess?.timestamp, i), false)}>
                    {sess?.type === EventType.DomContentLoaded && (
                      <span>Dom content load</span>
                    )}
                    {sess?.type === EventType.Load && (
                      <span>Load</span>
                    )}
                    {sess?.type === EventType.Meta && (
                      <span>Meta {JSON.stringify(sess?.data)}</span>
                    )}
                    {sess?.type === EventType.FullSnapshot && (
                      <span>Full snapshot</span>
                    )}
                    {sess?.type === EventType.IncrementalSnapshot && sess?.data?.source === IncrementalSource.MouseMove && (
                      <span>Mouse move ({session?.data?.x},{session?.data?.y})</span>
                    )}
                    {sess?.type === EventType.IncrementalSnapshot && sess?.data?.source === IncrementalSource.MouseInteraction && (
                      <span>Mouse interaction ({session?.data?.x},{session?.data?.y})</span>
                    )}
                    <br /><strong>{dayjs(getDuration(session?.data?.sessionEvents, sess?.timestamp, i)).format("mm:ss.SSS")}</strong>
                  </List.Item>
                ))}
              </List>

            </Panel>
          </Col>
        </Row>
      </Grid>
      
    </div>
  )
}

export default SessionReplay;