import { httpAuthClient } from "./client"

const getUser = async (userId: string) => {
  const httpClient = await httpAuthClient()
  const {data} = await httpClient.get(`/users/${userId}`)
  return data
}

const createUser = async (newData: any) => {
  const httpClient = await httpAuthClient()
  const {data} = await httpClient.post(`/users`, newData)
  return data
}


export const userApi = {
  getUser,
  createUser
}