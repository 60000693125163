import React from 'react'

const TaskOutputs = () => {
  return (
    <>
      <h1>Task outputs</h1>
    </>
  )
}

export default TaskOutputs