import React, { useCallback, useEffect, useState } from "react"
import { Background, ReactFlow, useEdgesState, ReactFlowProvider, useNodesState, useReactFlow } from "reactflow"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, Outlet, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import AceEditor from "react-ace";
import 'reactflow/dist/style.css';
import ExecutionNode from "./ExecutionNode";
import DefaultNode from "./DefaultNode";

import { getLayoutedElements, toEdges, toNodes } from "./utils";
import './style.less'
import { Ace } from "ace-builds";
import { useCustomQuery } from "../../../core/hooks/use-custom-query";
import { cloudxApi } from "../../../core/http/cloudx";
import { useQuery } from "react-query";
import { Message, Nav } from "rsuite";
import InfraMock from './mock.json'

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

const InfraView = () => {
  const location = useLocation()
  const { orgId, workspaceId, infraId } = useParams()
  const { fitView } = useReactFlow();
  const navigate = useNavigate()
  const [lastExecution, setLastExecution] = useState<any>()
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { error } = useCustomQuery("infraView", () => cloudxApi.getInfra(orgId || "", workspaceId || "", infraId || ""), {
    retry: 0,
    refetchInterval: 5000,
    onSuccess: (data) => {
      setInfra(data)
    },
    onError: () => {
      setInfra(InfraMock)
    }
  })
  const [infra, setInfra] = useState<any>()
  const [infraInterval, setInfraInterval] = useState<any>()
  const [infraEditorValue, setInfraEditorValue] = useState<any>('')
  const [hasModifications, setHasModifications] = useState<boolean>(false)
  const [show, setShow] = useState(false);
  const [currTask, setCurrTask] = useState<any>(null)
  const [mode, setMode] = useState<string>('info')

  useEffect(() => {
    if (infra && !lastExecution && mode == 'last-execution') {
      setLastExecution(infra?.status)
      return
    }

    setLastExecution(null)
  }, [infra, mode])

  const setNodesAndEdges = useCallback((n: any[], e: any[], nodeType: string, animated: boolean) => {
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      toNodes(n, nodeType),
      toEdges(e, animated),
    );

    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  }, [])

  useEffect(() => {
    try {
      console.log('EDIT')
      const infraEditorObject = JSON.parse(infraEditorValue)
      const newInfra = { ...infra, ...infraEditorObject }
      setNodesAndEdges(newInfra?.tasks, newInfra?.tasks, "defaultNode", false)
      setHasModifications(true)
    } catch(error) {
      console.log(error)
    }
  }, [infraEditorValue])

  useEffect(() => {
    if (!infra) return

    if (!!lastExecution) {
      setLastExecution(infra?.status)
      return
    }

    setInfraEditorValue(JSON.stringify({
      description: infra?.description,
      providerConfigRef: infra?.providerConfigRef,
      runnerConfigRef: infra?.runnerConfigRef,
      tasks: infra?.tasks,
    }, null, 2))
    setNodesAndEdges(infra?.tasks, infra?.tasks, "defaultNode", false)
  }, [infra])

  useEffect(() => {
    if (infra && !lastExecution) {
      setNodesAndEdges(infra?.tasks, infra?.tasks, "defaultNode", false)
      return
    }

    if (lastExecution)
      setNodesAndEdges(lastExecution?.tasks, lastExecution?.tasks, "executionNode", true)
  }, [lastExecution])

  return (
    <>
      <div style={{ height: 'calc(100vh - 150px)', display: 'flex' }}>
        
        <div
          style={{ width: '40%',  height: '100%', zIndex: 10 }}
          className="p-2 bg-light"
        >
          <Nav appearance="tabs" activeKey={mode} style={{ marginBottom: 10 }} onSelect={(eventKey) => setMode(eventKey || "info")}>
            <Nav.Item eventKey="info" >Info</Nav.Item>
            <Nav.Item eventKey="last-execution">Last execution</Nav.Item>
          </Nav>
          <div className="mt-2">
            {mode === 'info' && (
              <AceEditor
                style={{ width: '100%', height: 'calc(100vh - 185px)' }}
                mode="json"
                theme="github"
                name="infra-editor"
                onChange={(value) => JSON.stringify(infra, null, 2)}
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={JSON.stringify(infra, null, 2)}
                setOptions={{
                  useWorker: false,
                  showLineNumbers: true,
                  tabSize: 2,
                }}
              />
            )}
            {mode === 'last-execution' && (
              <>
                <h5>Last execution</h5>
                <small>Cloudx save only last execution, if you want to save more, use webhooks.</small>
                {infra?.status?.status === "ERROR" && (
                  <Message style={{ cursor: 'pointer' }} type="error" className="my-2" onClick={() => setLastExecution(infra?.status)}>
                    <strong>Error: </strong>{infra?.status?.error?.message}<br/>
                    <strong>Code: </strong>{infra?.status?.error?.code}<br/>
                    <strong>Message: </strong>{infra?.status?.error?.tip}<br/>
                    <strong>Started At: </strong>{infra?.status?.startedAt}<br/>
                  </Message>
                )}
                {infra?.status?.status === "" && (
                  <Message style={{ cursor: 'pointer' }} type="info" className="my-2" onClick={() => setLastExecution(infra?.status)}>
                    Not executed yet

                  </Message>
                ) }
                {infra?.status?.status === "SUCCESS" && (
                  <>
                    <strong>Last execution: </strong>
                    <Message type="success" className="my-2" onClick={() => setLastExecution(infra?.status)}>
                      The last execution executed successfully
                    </Message>
                  </>
                )}
                {infra?.status?.status === "RUNNING" && (
                  <>
                    <strong>Last execution: </strong>
                    <Message type="info" className="my-2" onClick={() => setLastExecution(infra?.status)}>
                      Running...
                    </Message>
                  </>
                )}
              </>
            )}
          </div>
        </div>

        
        
        <div style={{ width: '100%', height: '100%' }}>  
          <ReactFlow
            nodes={nodes}
            edges={edges} 
            nodeTypes={{
              executionNode: ExecutionNode,
              defaultNode: DefaultNode,
            }}
            fitView
            fitViewOptions={{maxZoom: 1}}
            onNodeClick={(event, node) => navigate(`/workspaces/${workspaceId}/infras/${infra?.name}/last-execution/task/${node?.data?.name}`)}
          >
            <Background/>
          </ReactFlow>
        </div>
      </div>

      <Outlet context={[infra, setInfra]}  />
    </>
  )
}

export default (props: any) => (
  <ReactFlowProvider>
    <InfraView {...props} />
  </ReactFlowProvider>
)