import { useEffect, useState } from "react";
import {
  Panel as RPanel,
  Stack as RStack,
  ButtonGroup as RButtonGroup,
  Toggle as RToggle,
  InputGroup,
  Input,
} from "rsuite";
import Modal from "../../core/components/Modal";
import { Nav } from "rsuite";
import { useForm } from "react-hook-form";
import { watch } from "fs";

enum UserRole {
  ADMIN = "admin",
  USER = "user",
  ANALYST = "analyst",
}

const InviteUser = () => {
  const [role, setRole] = useState<UserRole | null>(null);
  const { register, handleSubmit, getValues, watch } = useForm();
  const { onChange, name, ref } = register("email", {
    required: true,
  });

  const onClick = (role: UserRole, checked: boolean) => {
    setRole(checked ? role : null);
  };

  const onSubmit = () => {
    const { email } = getValues();
    return email === "leandro@insight.com";
  };

  const onConfirmInvite = (done: Function) => {
    if (onSubmit()) {
      done();
    }
  };

  return (
    <Modal
      title="Convidar Usuário"
      trigger={
        <Nav.Item className="rs-sidenav-item">Convidar usuário</Nav.Item>
      }
      disableConfirm={!role || !watch("email")}
      onConfirm={onConfirmInvite}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <p style={{ marginBottom: "20px" }}>
          Convide membros da sua equipe para atuar nestá organização. <br />
          Escolha o usuário e o perfil de acesso.
        </p>
        <InputGroup inside style={{ marginBottom: "20px" }}>
          <Input
            ref={ref}
            name={name}
            onChange={(_, event) => onChange(event)}
            placeholder="Insira o email do usuário que deseja convidar."
          />
        </InputGroup>
        <RPanel
          style={{ marginBottom: "20px" }}
          header={
            <RStack justifyContent="space-between">
              <span>Administrador</span>
              <RButtonGroup>
                <RToggle
                  size="md"
                  checked={role === UserRole.ADMIN}
                  onChange={(checked: boolean) =>
                    onClick(UserRole.ADMIN, checked)
                  }
                />
              </RButtonGroup>
            </RStack>
          }
          bordered
        >
          <ul>
            <li>
              Pode gerenciar usuários, atribuir e modificar perfis de acesso.
            </li>
            <li>Acesso completo a todas as funcionalidades e dados.</li>
            <li>Pode configurar configurações globais da aplicação.</li>
          </ul>
        </RPanel>
        <RPanel
          style={{ marginBottom: "20px" }}
          header={
            <RStack justifyContent="space-between">
              <span>Usuário</span>
              <RButtonGroup>
                <RToggle
                  size="md"
                  checked={role === UserRole.USER}
                  onChange={(checked: boolean) =>
                    onClick(UserRole.USER, checked)
                  }
                />
              </RButtonGroup>
            </RStack>
          }
          bordered
        >
          <ul>
            <li>Pode acessar as funcionalidades principais da aplicação.</li>
            <li>
              Pode criar, visualizar, modificar e excluir seu próprio conteúdo.
            </li>
          </ul>
        </RPanel>
        <RPanel
          style={{ marginBottom: "20px" }}
          header={
            <RStack justifyContent="space-between">
              <span>Analista</span>
              <RButtonGroup>
                <RToggle
                  size="md"
                  checked={role === UserRole.ANALYST}
                  onChange={(checked: boolean) =>
                    onClick(UserRole.ANALYST, checked)
                  }
                />
              </RButtonGroup>
            </RStack>
          }
          bordered
        >
          <ul>
            <li>Tem acesso a ferramentas analíticas e relatórios.</li>
            <li>
              Tem permissões para visualizar, mas não modificar dados críticos.
            </li>
          </ul>
        </RPanel>
      </form>
    </Modal>
  );
};

export default InviteUser;
