import { useIsFetching, useQuery, QueryOptions, UseQueryOptions } from "react-query"

const defaultOptions = {
  retry: 3,
  refetchOnWindowFocus: false
}



export const useCustomQuery = (queryKey: string, queryFn: () => Promise<any>, options: UseQueryOptions = {}) => {

  const currOptions = {
    ...defaultOptions,
    ...options,
  } as any

  const { data, error, isLoading, isFetching, refetch, status } = useQuery(queryKey, queryFn, currOptions)

  return {
    status,
    data,
    error,
    isLoading,
    refetch,
    isFetching
  }
}