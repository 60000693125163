import React, { useState } from 'react'
import { useCustomQuery } from '../../../core/hooks/use-custom-query'
import { cloudxApi } from '../../../core/http/cloudx'
import { Link, useParams } from 'react-router-dom'
import { Container, Content, FlexboxGrid, Table } from 'rsuite'

const { Column, HeaderCell, Cell } = Table;

const Infras = () => {
  const { orgId, workspaceId } = useParams()
  const [infras, setInfras] = useState<any[]>([])
  const { isLoading } = useCustomQuery("cloudxCurrentInfras", () => cloudxApi.getInfras(orgId || "", workspaceId || ""), {
    retry: 0,
    onSuccess(data: any) {

    },
    onError(err) {
      setInfras([{
        id: "a752d8c6-ce00-42fa-9fcb-fa6f0bdb1a6e",
        name: "fake-infra-1"
      }])
    }
  })

  return (
    <Container>
      <Content>
        <FlexboxGrid justify="center">
        <FlexboxGrid.Item colspan={20}>
        <h3>Infras</h3>
        <Table
          loading={isLoading}
          autoHeight={true}
          bordered={false}
          data={infras}
          onRowClick={rowData => {
            console.log(rowData);
          }}
        >
          <Column width={300} fixed>
            <HeaderCell>Id</HeaderCell>
            <Cell dataKey="id" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Name</HeaderCell>
            <Cell dataKey="name" />
          </Column>

          <Column fixed>
            <HeaderCell>...</HeaderCell>
            <Cell style={{ padding: '6px' }}>
              {rowData => (
                <Link to={`/orgs/${orgId}/cloudx/workspaces/${workspaceId}/infras/${rowData.id}`}>Detail</Link>
              )}
            </Cell>
          </Column>
        </Table>
        </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
    </Container>
  )
}

export default Infras