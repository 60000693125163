import React from 'react';
import ReactDOM from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./core/components/Icon/icon"


Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: 'us-east-1_emuPtlqta',
      userPoolClientId: '7v6tn3sfmvs4toajd5ihqqrvba',
      signUpVerificationMethod: 'code', // 'code' | 'link'
      loginWith: {
        oauth: {
          domain: 'https://elantr.auth.us-east-1.amazoncognito.com',
          scopes: [
            'phone',
            'email',
            'profile',
            'openid',
            'aws.cognito.signin.user.admin'
          ],
          redirectSignIn: ['http://localhost:3000/'],
          redirectSignOut: ['http://localhost:3000/'],
          responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
      }
    }
  }
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
