import React, { useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useSearchParams } from "react-router-dom"
import { getCurrentSession } from "../../core/helpers/auth"
import { httpAuthClient } from "../../core/http/client"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {Badge, Button, Col, FlexboxGrid, Grid, Input, InputGroup, Nav, Panel, Row, Stack, Toggle} from "rsuite";
import { orgApi } from "../../core/http/orgs"
import { useCustomQuery } from "../../core/hooks/use-custom-query"
import { productApi } from "../../core/http/products"

const currency = "BRL"

const Products = ({ products, onSelectProducts }: any) => {
  const { status, data, error, isFetching, refetch } = useCustomQuery('products', productApi.list);
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedCategory, setSelectedCategory] = useState<any>()
  const [categories, setCategories] = useState(new Map())
  const [cart, setCart] = useState<any[]>(products || [])
  const [totalValue, setTotalValue] = useState(0)


  const handleAddToCart = (product: any) => {
    const price = product.prices?.find((price: any) => price?.recurring?.interval === "month")
    if (cart.find((item: any) => item.id === product?.id)) {
      setCart(cart.filter((item: any) => !(item.id === product?.id)))
      return
    }

    if (cart.find((item: any) => item.id === product?.id)) {
      const filtered = cart.filter((item: any) => item.id !== product?.id)
      setCart([...filtered, {id: product?.id, priceId: price?.id, category: product?.category}])
      return
    }

    if (!cart.find((item: any) => item.id === product?.id)) {
      setCart([...cart, {id: product?.id,  priceId: price?.id, category: product?.category}])
    }
  }

  const handleClearCart = () => {
    localStorage.removeItem("cart")
    setCart([])
  }

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart))
    const totalValue = cart.reduce((acc: any, item: any) => {
      const product = data?.find((product: any) => product?.id === item?.id)
      const price = product?.prices?.find((price: any) => price?.id === item?.priceId)

      return acc + price?.value
    }, 0)

    setTotalValue(totalValue / 100)
  }, [cart])

  useEffect(() => {
    onSelectProducts({
      products: cart?.map((item: any) => ({ productId: item?.id, priceId: item?.priceId })),
      totalValue,
    })
  }, [cart, totalValue])

  useEffect(() => {
    if (products) {
      setCart(products)
    }
  }, [products])

  useEffect(() => {
    if (data) {
      const newCategories = new Map()
      data?.forEach((product: any) => {
        if (!newCategories.has(product?.category)) {
          newCategories.set(product?.category, [])
        }

        newCategories.set(product?.category, [...newCategories.get(product?.category), { ...product, quantity: 1 }])
      })
      setCategories(newCategories)
    }
  }, [data])

  useEffect(() => {
    if (!selectedCategory) return
    setSearchParams({ ...Object.fromEntries(searchParams), category: selectedCategory })
  }, [selectedCategory])

  return (
    <div style={{position: 'relative', height: '100%'}}>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button appearance="ghost" onClick={handleClearCart}>Limpar seleção</Button>
      </div>
      <Nav appearance="subtle" activeKey={searchParams.get("category")} style={{ marginBottom: "20px" }}>
        {Object.keys(Object.fromEntries(categories)).map((categoryKey: any) => (
          <Nav.Item eventKey={categoryKey} onClick={() => setSelectedCategory(categoryKey)}>
            {categoryKey} {cart?.filter((item: any) => item?.category === categoryKey).length > 0 && (
              <Badge content={cart?.filter((item: any) => item?.category === categoryKey).length}></Badge>
            )}
          </Nav.Item>
        ))}
      </Nav>
  
      <Stack wrap>
        {categories.get(selectedCategory)?.map((product: any) => (
          <Panel bordered header={product?.name} style={{ width: "400px", background: "#fff", textAlign: "center" }}>
            <Nav activeKey="month">
              <Nav.Item >
                <Nav.Item eventKey="month">Mensal</Nav.Item>
              </Nav.Item>
              <Nav.Item >
                <Nav.Item eventKey="yearly">Anual</Nav.Item>
              </Nav.Item>
            </Nav>

            <div>
              {product?.prices?.filter((price: any) => price?.recurring?.interval === "month").map((price: any) => (
                <div>
                  R$ <div style={{ fontSize: "26px" }}>{price?.value / 100},00</div>
                </div>
              ))}
            </div>
            <div style={{color: "#6c757d", fontSize: "12px"}}>{product?.description}</div>
            <ul style={{ textAlign: "left"}}>
              {product?.features?.map((feature: any) => (
                <li>{feature?.name}</li>
              ))}
            </ul>
            <FlexboxGrid justify="end">
              <FlexboxGrid.Item>
                <Toggle
                  id={product.id}
                  onChange={() => handleAddToCart(product)}
                  checked={!!cart?.find((item: any) => item?.id === product?.id)}
                />
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Panel>
        ))}
      </Stack>
    </div>
  )
}

export default Products