import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Icon as RIcon } from '@rsuite/icons';

const Icon = ({ faIcon, svg }: any) => {
  if (faIcon) {
    // @ts-ignore
    return <RIcon as={FontAwesomeIcon} icon={faIcon} />
  }

  return <RIcon as={svg} />
}

export default Icon